<template>
  <div class="row">
    <div class="col">
      <p v-if="!code_sent" class="alert alert-primary mt-4">
        Para activar tu cuenta, es necesario que verifiques tu número de
        teléfono. Te enviaremos un código de activación a través de un mensaje
        de texto.
      </p>
      <p v-if="code_sent" class="alert alert-warning">
        Te hemos enviado un mensaje de texto a tu teléfono con el código de
        activación.
      </p>
      <div class="card shadow">
        <div class="card-body">
          <div class="form-group mb-2">
            <label for="">Teléfono</label>
            <input
              type="text"
              class="form-control text-center"
              v-model="phone_number"
            />
          </div>
          <button
            v-if="!code_sent"
            @click="sendCode"
            class="btn btn-primary w-100"
          >
            Enviar Código SMS
          </button>
          <div v-if="code_sent" class="form-group mb-3">
            <label for="">Ingrese el código SMS</label>
            <input
              v-model="code"
              type="text"
              class="form-control text-center"
              autofocus="autofocus"
            />
          </div>
          <button
            v-if="code_sent"
            @click="activate"
            class="btn btn-primary w-100"
          >
            Confirmar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import API from "@/api";

export default defineComponent({
  setup() {
    const route = useRoute();
    const router = useRouter();

    const phone_number = ref(route.params.phone_number || "");
    const code_sent = ref(false);
    const code = ref("");

    const sendCode = async () => {
      try {
        const response = await API.post(
          `v1/users/send-code/${phone_number.value}`
        );

        code_sent.value = true;
      } catch (error) {
        console.log(error);
      }
    };

    const activate = async () => {
      try {
        const response = await API.post(
          `v1/users/activate/${phone_number.value}`,
          {
            code: code.value,
          }
        );

        localStorage.setItem("token", response.data.token);

        router.push({ name: "password-set" });
      } catch (error) {
        console.log(error);
      }
    };

    return {
      phone_number,
      code_sent,
      code,
      sendCode,
      activate,
    };
  },
});
</script>
